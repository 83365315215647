import Template from './TemplateSheet'

const SheetC = () => {
    
    const list = [
        {
            title: 'Elevação lateral máquina',
            videoId: '_a25iVzjrNc',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Elevação Frontal com Halter de Mão Alternado',
            videoId: 'jhxLYSm_P-k',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Crucifixo inverso na máquina',
            videoId: 'B_piYGlAKb4',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '30'
        },
        {
            title: 'Desenvolvimento com Halter',
            videoId: 'L-iQfHVeuVg',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Encolhimento dos Ombros com Halter',
            videoId: 'YeILDnoeYEk',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Tríceps testa na polia com corda',
            videoId: '-bPNFre0hv0',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Tríceps Máquina',
            videoId: 'lIMTRXECHmg',
            type: 'Tradicional',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Triceps mergulho banco',
            videoId: 'bd_VvOCpdKM',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Tríceps Corda',
            videoId: 'gbnLZto6b0s',
            type: 'Drop Set',
            series: '3',
            repeat: '10',
            interval: '40'
        },
        {
            title: 'Esteira',
            image: 'https://melhordolar.com.br/wp-content/uploads/2020/04/Melhor-Esteira-Ergometrica.jpeg',
            type: 'Tradicional',
            series: '1',
            repeat: '10',
            interval: '0'
        }
    ]

    let elements = [];

    list.forEach(item => {
        elements.push(
            <Template key={item.videoId} videoId={item.videoId ?? null} image={item.image ?? null} title={item.title} type={item.type} series={item.series} repeat={item.repeat} interval={item.interval} description={item.description ?? null} />
        )
    })

    return (
        <div>
            {elements}
        </div>
    )
}

export default SheetC;