import React, { useState } from 'react';

import timer from '../../Assets/img/timer.png'
import './style.css'

const Timer = () => {

    let [time, setTime] = useState(0)
    let [start, setStart] = useState(false)
    let [interv, setInterv] = useState(null)

    const handlerStart = () => {
        
        const itv = setInterval(() => {
            setTime(time++)
            console.log(time);
        }, 1000)

        setInterv(itv)
        setStart(true)
    }

    const handlerStop = () => {
        setStart(false)
        clearInterval(interv)
    }

    const handlerReset = () => {
        setTime(0)
    }

    const getTime = () => {
        return new Date(time * 1000).toISOString().substr(11, 8);
    }

    return (
        <div className='timerContainer'>
            {(!start) ? <button className='btn btn-green' onClick={handlerStart}>Iniciar treino</button> : ''}

            {(start) ? <div><button className='btn btn-red' onClick={handlerStop}>Terminar treino</button></div> : ''}

            <span className='timer'>{getTime()} {(!start) ? <img className='reset' onClick={handlerReset} src={timer} alt='Reiniciar temporizador' title='Reiniciar temporizador' /> : ''}</span>
        </div>
    )
}

export default Timer;