import Profile from './Components/Profile'
import Workout from './Components/Workout/index'
import './App.css'

function App() {
  return (
    <div className="App">
      <Profile name='Gunnar Correa'></Profile>
      <Workout></Workout>
    </div>
  );
}

export default App;
