import Template from './TemplateSheet'

const SheetA = () => {

    const list = [
        {
            title: 'Crucifixo 45',
            videoId: 'JbdxrCxVvw4, uxrJNaQaGSk',
            type: 'Bi set',
            series: '4',
            repeat: '10',
            interval: '60',
            description: 'Bi Set - Supino Inclinado Barra',
        },
        {
            title: 'Crucifixo máquina',
            videoId: 'O-H9EOeFFz0',
            type: 'Tradicional',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Supino reto',
            videoId: 'WwXS2TeFmeg',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '60'
        },
        {
            title: 'Supino Declinado',
            videoId: 'ifWEwZDWMAw',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Rosca Bíceps na Barra W',
            videoId: '017j7Gbxipo',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Rosca Bíceps Banco Scoth',
            videoId: 'Kh4G5N48EO8',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Rosca martelo na polia com corda',
            videoId: 'Le9cUbODJHY',
            type: 'Drop set',
            series: '3',
            repeat: '10',
            interval: '60',
            description: '10 > 12 > 15 > descanso'
        },
        {
            title: 'Bicicleta',
            image: 'https://www.casasbahia-imagens.com.br/html/conteudo-produto/418/15120188/imagens/bicicleta_1.png',
            type: 'Tradicional',
            series: '3',
            repeat: '10',
            interval: '0'
        }
    ]

    let elements = [];

    list.forEach(item => {
        elements.push(
            <Template key={item.videoId} videoId={item.videoId ?? null} image={item.image ?? null} title={item.title} type={item.type} series={item.series} repeat={item.repeat} interval={item.interval} description={item.description ?? null} />
        )
    })

    return (
        <div>
            {elements}
        </div>
    )
}

export default SheetA;