import Template from './TemplateSheet'

const SheetB = () => {
    
    const list = [
        {
            title: 'Remada Sentado Triângulo',
            videoId: 'WxkMoxuMSho',
            type: 'Tradicional',
            series: '4',
            repeat: '10',
            interval: '30'
        },
        {
            title: 'Remada máquina unilateral',
            videoId: 'VmGs8dwQ9Zg',
            type: 'Tradicional',
            series: '4',
            repeat: '10',
            interval: '30'
        },
        {
            title: 'Remada curvada pegada pronada',
            videoId: 'z3K0pf1nOgI',
            type: 'Tradicional',
            series: '4',
            repeat: '8-8-6-6',
            interval: '30'
        },
        {
            title: 'Pull Down pegada Inversa',
            videoId: 'vzyTs6rfdfE',
            type: 'Tradicional',
            series: '4',
            repeat: '10',
            interval: '40'
        },
        {
            title: 'Puxada alta barra frente',
            videoId: 'sAAge4YxIAc',
            type: 'Tradicional',
            series: '4',
            repeat: '10',
            interval: '60'
        },
        {
            title: 'Lombar banco dorsal',
            videoId: '1pBx0LQwcOo',
            type: 'Tradicional',
            series: '3',
            repeat: '10',
            interval: '40'
        },
        {
            title: 'Abdominal crunch',
            videoId: '_g6RCj4cq4I',
            type: 'Tradicional',
            series: '3',
            repeat: '8',
            interval: '60'
        },
        {
            title: 'Abdominal oblíquo máquina crunch',
            videoId: 'sD_OFh0pfZ8',
            type: 'Tradicional',
            series: '3',
            repeat: '10',
            interval: '40'
        },
        {
            title: 'Abdominal machine',
            videoId: 'BNynZ9D8DEc',
            type: 'Tradicional',
            series: '4',
            repeat: '10',
            interval: '0'
        }
    ]

    let elements = [];

    list.forEach(item => {
        elements.push(
            <Template key={item.videoId} videoId={item.videoId ?? null} image={item.image ?? null} title={item.title} type={item.type} series={item.series} repeat={item.repeat} interval={item.interval} description={item.description ?? null} />
        )
    })

    return (
        <div>
            {elements}
        </div>
    )
}

export default SheetB;