import React, { useState } from 'react'
import Youtube from '../Youtube'

//Images
import weight from '../../Assets/img/weight.png'
import check from '../../Assets/img/check.png'

const TemplateSheet = (props) => {

    const [show, setshow] = useState('hidden')
    const [showVideo, setShowVideo] = useState(false)

    const ref = React.createRef()

    const toggleShow = () => {

        if (show == 'hidden') {
            ref.current.querySelector('.sheetBody').classList.remove('hidden')
            ref.current.querySelector('.sheetBody').classList.add('show')
            setshow('show')
            setShowVideo(true)
        }
        else {
            ref.current.querySelector('.sheetBody').classList.remove('show')
            ref.current.querySelector('.sheetBody').classList.add('hidden')
            setshow('hidden')
        }
    }

    const hide = () => {
        ref.current.style.display = 'none'
    }

    const getVideos = () => {
        if (showVideo == false)
            return;

        if (props.videoId != null)
            return <Youtube videoId={props.videoId} />
    }

    return (
        <div className='sheet' ref={ref}>
            <div className='sheetHeader' onClick={toggleShow}>
                <img src={weight} alt={props.title} />
                <span>{props.title}</span>
            </div>

            <div className='sheetBody hidden'>
                <div className='grid'>
                    <div>
                        <p><img src={check} alt='Check' /> {props.type}</p>
                        <p><img src={check} alt='Check' /> {props.series} séries</p>
                    </div>

                    <div>
                        <p><img src={check} alt='Check' /> <strong>{props.repeat}</strong> repetições</p>
                        <p><img src={check} alt='Check' /> <strong>{props.interval}</strong> seg. intervalo</p>
                    </div>
                </div>

                {props.description != null ? <p><img src={check} alt='Check' /> {props.description}</p> : ''}

                {getVideos()}

                {props.image != null ? <img src={props.image} className='image-refer' /> : ''}

                <div className='actions'>
                    <button className='btnConcluir' onClick={hide}>Concluir</button>
                </div>
            </div>
        </div>
    )
}

export default TemplateSheet;