import React from 'react'
import './style.css'

const Youtube = (props) => {
    let videoList = props.videoId.split(',')

    let videos = [];

    videoList.forEach(video => {
        video = video.trim();
        
        videos.push(
            <div className="videoWrapper" key={video}>
                <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${video}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        )
    })
    return (
        <div>
            {videos}
        </div>
    )
}

export default Youtube;