import React, { useState } from 'react'
import Timer from '../../Components/Timer'
import SheetA from './SheetA'
import SheetB from './SheetB'
import SheetC from './SheetC'
import SheetD from './SheetD'

import './style.css'

const Workout = () => {

    const [sheet, setSheet] = useState('A')

    const handleSheeter = (event) => {
        setSheet(event.target.value)
    }

    const getSheet = () => {
        if (sheet == 'A')
            return <SheetA />
        else if (sheet == 'B')
            return <SheetB />
        else if (sheet == 'C')
            return <SheetC />
        else if (sheet == 'D')
            return <SheetD />
    }

    return (
        <div>
            <div className='buttons'>
                <button onClick={handleSheeter} value='A' className={sheet == 'A' ? 'active' : ''}>A</button>
                <button onClick={handleSheeter} value='B' className={sheet == 'B' ? 'active' : ''}>B</button>
                <button onClick={handleSheeter} value='C' className={sheet == 'C' ? 'active' : ''}>C</button>
                <button onClick={handleSheeter} value='D' className={sheet == 'D' ? 'active' : ''}>D</button>
            </div>

            <Timer></Timer>

            <div className='hr'></div>

            <div>
                {getSheet()}
            </div>
        </div>
    )
}

export default Workout;