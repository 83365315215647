import React from 'react'
import './style.css'
import thumb from '../../Assets/img/gunnar-correa.jpg'

const Profile = (props) => {
    return (
        <div className='profile'>
            <img src={thumb} alt={props.name} width={75} height={75}/>
            <span>{props.name}</span>
        </div>
    )
}

export default Profile;