import Template from './TemplateSheet'

const SheetD = () => {
  
    const list = [
        {
            title: 'Cadeira Extensora',
            videoId: 'y6juG3XuRe4',
            type: 'Tradicional',
            series: '4',
            repeat: '8',
            interval: '40'
        },
        {
            title: 'Leg Press Inclinado 45°',
            videoId: '78mCZPObzug',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '40'
        },
        {
            title: 'Agachamento smith',
            videoId: 'uDBQtlCLQ0Y',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '60'
        },
        {
            title: 'Cadeira Abdutora',
            videoId: '50qHGus1TZk',
            type: 'Tradicional',
            series: '4',
            repeat: '8',
            interval: '60'
        },
        {
            title: 'Stiff Barra reta',
            videoId: 'vXPbKrYIEaQ',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '60'
        },
        {
            title: 'Cadeira Flexora',
            videoId: 'AFG0wxXmTH4',
            type: 'Pirâmide decrescente',
            series: '4',
            repeat: '8-8-6-6',
            interval: '60'
        },
        {
            title: 'Gêmeos no leg press horizontal',
            videoId: 'zusySgJMqCg',
            type: 'Tradicional',
            series: '4',
            repeat: '8-8-6-6',
            interval: '60'
        },
        {
            title: 'Simulador de Degrau',
            videoId: 'mkO1ypCMJVY',
            type: 'Tradicional',
            series: '1',
            repeat: '10',
            interval: '0'
        }
    ]

    let elements = [];

    list.forEach(item => {
        elements.push(
            <Template key={item.videoId} videoId={item.videoId ?? null} image={item.image ?? null} title={item.title} type={item.type} series={item.series} repeat={item.repeat} interval={item.interval} description={item.description ?? null} />
        )
    })

    return (
        <div>
            {elements}
        </div>
    )
}

export default SheetD;